<template>
    <div class="login">
        <el-card class="card px40">
            <p class="aliC fs16 bold6 pt16 pb30">纷享未来药房供应链管理系统</p>
            <el-form
                    ref="ruleForm"
                    :model="ruleForm"
                    status-icon
                    :rules="rules"
                    label-width="50px"
                    class="pr30"
                 >
                <el-form-item label="帐号" prop="mobile">
                    <el-input v-model="mobile"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="pass">
                    <el-input
                            v-model="pass"
                            type="password"
                            autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item class="mt40">
                    <el-button class="btn" type="primary" @click="doLogin">登录</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>


</template>

<script>
    import api from '@/config/api'
    import { ElMessage } from 'element-plus'

    export default {
        data() {
            return {
                pass: '',
                mobile: '',
            }
        },
        methods: {
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    console.info(valid);
                    if (valid) {
                        this.doLogin();
                    } else {

                        return false
                    }
                })
            },

            async doLogin() {
                console.info("=====dologin===")
                if(this.mobile == ''){
                    ElMessage({
                        showClose: true,
                        message: '手机号不能为空',
                        type: 'warning',
                    })
                    return;
                }
                if(this.pass == ''){

                    ElMessage({
                        showClose: true,
                        message: '密码不能为空',
                        type: 'warning',
                    })
                    return;
                }
                let data = {
                    mobile:this.mobile,
                    password:this.pass
                }
                const res = await api.login(data);
                console.info("==login=="+JSON.stringify(res))
                if(res.code == '1'){
                    var username = res.data.username;
                    var token = res.data.token;
                    var mobile = res.data.mobile;
                    sessionStorage.setItem("token",token);
                    sessionStorage.setItem("mobile",mobile);
                    sessionStorage.setItem("username",username);
                    this.$router.replace('/home')
                }else {
                    ElMessage({
                        showClose: true,
                        message: res.msg,
                        type: 'error',
                    })
                }
            },
            resetForm(formName) {
                this.$refs[formName].resetFields()
            },
        },
    }
</script>
<style type="text/css" lang="scss" scoped>
    .login {
        width: 100%;
        height: 100vh;
        background: url(../../assets/img/bg.jpg) no-repeat center;
        background-size: 100% 100%;
    }

    .card {
        width: 400px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .btn {
            width: 180px;
        }
    }
</style>